@import "Theme";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";

:export {
  xs: map-get($grid-breakpoints, xs);
  sm: map-get($grid-breakpoints, "sm");
  md: map-get($grid-breakpoints, "md");
  lg: map-get($grid-breakpoints, "lg");
  xl: map-get($grid-breakpoints, "xl");
  xxl: map-get($grid-breakpoints, "xxl");
}

.btn-primary {
  color: white !important;
}

.btn-outline-primary {
  --bs-btn-hover-color: #fff;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-hover-color: #fff;
}

.nav-link {
  color: #fff !important;
}

.nav-tabs .nav-link {
  color: black !important;
}

.btn {
  // The original template for the new UI changed the button style, so this stays here until we are sure we don't want that
  /*  --bs-btn-font-size: 0.8rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 38px;
  min-width: 38px;
   */

  svg {
    font-size: 1rem;
    vertical-align: -0.125em;
  }

  span:nth-child(2) {
    margin-left: 0.75rem;
  }

  span:nth-child(1) {
    margin-right: 0.75rem;
  }

  span {
    margin-top: -1px;
  }
}

.btn-success {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: var(--bs-success) !important;
  --bs-btn-border-color: #008000 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #006000;
  --bs-btn-hover-border-color: #006000;
  --bs-btn-focus-shadow-rgb: 130, 138, 245;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #006000;
  --bs-btn-active-border-color: #006000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #595959;
  --bs-btn-disabled-border-color: #595959;
}

// allows alerts to have an opt-in interactive design
// using $theme-colors-bg-subtle instead of $theme-colors, because $theme-colors has keys (that we added)
// that cannot be found in the other maps
@each $state in map-keys($theme-colors-bg-subtle) {
  $alert-background: map-get($theme-colors-bg-subtle, $state);
  $alert-border: map-get($theme-colors-border-subtle, $state);

  .alert-#{$state}.interactive {
    cursor: pointer;

    &:hover {
      background-color: shade-color(
        $alert-background,
        $btn-hover-bg-shade-amount
      );
      border-color: shade-color($alert-border, $btn-hover-bg-shade-amount);
    }

    &:active {
      background-color: shade-color(
        $alert-background,
        $btn-active-bg-shade-amount
      );
      border-color: shade-color($alert-border, $btn-active-bg-shade-amount);
    }
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .dx-popover-arrow {
    display: none;
  }

  .dx-popup-bottom .dx-button {
    min-width: min-content;
  }
}

/* Setting a custom font-size destroys the whole ui. We should consider not changing this
:root {
    --bs-body-font-size: 14px !important;
    --bs-dropdown-font-size: 14px !important;
    --bs-heading-color: #156FC1;
}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-header .btn-close {
  @include btn-close-white();
}

.notistack-MuiContent-success {
  background-color: var(--bs-success);
}

.notistack-MuiContent-warning {
  background-color: var(--bs-warning);
}

.notistack-MuiContent-info {
  background-color: var(--bs-primary-light);
}

.notistack-MuiContent-error {
  background-color: var(--bs-danger);
}

fieldset {
  border-width: var(--bs-border-width);
  border-style: var(--bs-border-style);
  border-color: var(--bs-primary);
  background-color: white;
  border-radius: var(--bs-border-radius-lg);
  padding: var(--bs-p-3);
  .form-group > .form-label {
    margin-bottom: unset;
    display: flex;
    align-items: center;
  }
  legend {
    float: unset;
    width: fit-content;
    font-size: 16px;
    color: var(--bs-primary);
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: var(--bs-primary);
}

.modal-header {
  color: white;
  background-color: var(--bs-primary);
  h5 {
    color: white;
  }
}

.modal-title {
  color: white;
}

.modal-content {
  background-color: var(--bs-body-bg);
}

.form-label {
  font-size: 16px;
  color: var(--bs-primary);
  &.required {
    font-size: 16px;
    color: var(--bs-primary);
    font-weight: 500;
    &::after {
      content: "*";
    }
  }
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: white !important;
}

.bg-warning {
  background-color: var(--bs-imes-yellow) !important;
}

.was-validated .form-control:valid:focus:not(.is-warning),
.form-control.is-valid:focus:not(.is-warning) {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25) !important;
}

.form-control:not(.is-invalid):not(.is-warning),
.form-control.is-valid:not(.is-warning) {
  border-color: var(--bs-primary);
}

.form-control:disabled {
  border-color: black !important;
}

.form-select,
.form-select.is-valid {
  border-color: var(--bs-primary);
}

.border .border-primary .rounded-4 {
  border-width: var(--bs-border-width);
  border-style: var(--bs-border-style);
  border-color: var(--bs-primary);
  border-radius: var(--bs-border-radius-xl);
  background-color: white;
}

.text-primary {
  font-size: 16px;
  color: var(--bs-primary);
}
