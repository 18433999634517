.navmenu {
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 50px;
  width: 55px;
  height: calc(100vh - 50px);
  padding-bottom: 10px;
  padding-top: 5px;
  z-index: 3;
  background: rgb(0, 19, 32);
  /* background: linear-gradient(0deg, rgba(0, 19, 32, 1) 0%, rgba(1, 105, 180, 1) 100%); */
  background: linear-gradient(0deg, rgba(0, 19, 32, 1) 0%, #3387c3 100%);
  box-shadow: 1px 0 4px 0 black;
  clip-path: inset(0px -4px 0px 0px);
  user-select: none;
  transition: width 0.3s;
  // The original template for the new UI changed the font-size to 14px, so this stays here until we are sure we don't want that
  //font-size: 14px;

  & h3,
  & span {
    color: white;
  }

  &:global(.open) {
    width: 250px;
  }
}

//region NavigationItem
.navmenu,
.navItem {
  &:not(:global(.open)) .navGroup {
    height: 0 !important;
  }
}

.navItem {
  margin-top: 10px;
  overflow: hidden;
  border-radius: var(--bs-border-radius);

  &:first-child {
    margin-top: 0;
  }

  .navmenu:global(.open) &:global(.open) {
    background-color: var(--bs-lightblue-lighter);

    & .caret {
      transform: rotate(-90deg);
    }
  }

  &:global(.active) {
    background-color: var(--bs-lightblue-lighterer) !important;

    & > .navLink {
      font-weight: bold;
    }

    & > .navGroup {
      background-color: inherit;
    }
  }

  & * {
    color: white !important;
  }
}

.navLink {
  height: 37px;
  padding: 5px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-decoration: none;

  .navItem:global(.active) &:global(.active) {
    font-weight: bold;
  }

  &:hover {
    background-color: var(--bs-lightblue-hover);
    cursor: pointer;
  }

  & > div > svg {
    margin-right: 1rem;
  }

  & span {
    white-space: nowrap;
  }
}

.navGroup {
  width: 230px;
  overflow: hidden;
  transition: height 0.26s ease;
  background-color: var(--bs-lightblue-lighter);

  & > .navLink {
    padding: 0.5rem 1rem 0.5rem 1rem;

    .navItem:global(.active) &:global(.active) {
      box-shadow: inset 3px 0 0 0 white;
    }

    & svg {
      width: 20px;
    }
  }
}

.caret {
  transition: transform 0.3s;
}

//endregion
