.listGroupItemGroup {
  padding: 0 0 0 0.5rem !important;

  :global .list-group-item {
    border-right: none;

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      z-index: 0;
    }
  }
}

.buttonStyle {
  border: none !important;
  color: black !important;
  background-color: transparent !important;
  width: 100%;
  outline: none;

  &:hover {
    color: var(--bs-primary) !important;
    background-color: transparent !important;
  }

  &:active {
    color: var(--bs-primary) !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:focus {
    color: var(--bs-primary) !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}
