.customPopover {
  width: 500px;
  background-color: var(--bs-light) !important;
  border-radius: 1rem !important;
  box-shadow: 0 0 2px 0 black;
  display: flex !important;
  flex-direction: column;
  border: none !important;
  max-width: unset !important;
  max-height: 30rem;
  min-height: 10rem;

  & > *:first-child {
    &::before {
      border: none !important;
    }

    &::after {
      border-bottom-color: var(--bs-lightblue) !important;
      filter: invert(48%) sepia(70%) saturate(488%) hue-rotate(162deg)
        brightness(86%) contrast(91%);
    }
  }

  &Header {
    width: 100%;
    height: fit-content;
    background: var(--bs-primary);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-width: 0;
    padding: 1rem;
    text-align: center;

    & > div:first-child {
      display: flex;
      justify-content: center;
      align-items: center;

      & > h5 {
        font-size: 20px;
        color: white;
        margin-right: 0.5rem;
      }
    }

    & > div:last-child {
      display: flex;
      margin: -1rem;
      margin-top: 0.5rem;
      flex-direction: row;
    }
  }

  &Body {
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border: none;
  }

  /*We are not using this class yet */
  &Sorter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    padding-bottom: 0;

    & > span {
      margin-right: 20px;
    }

    & > button {
      font-size: 0.8rem;

      &:first-of-type {
        margin-right: 10px;
      }

      & > svg {
        margin-bottom: -2px;
        margin-left: 5px;
      }
    }
  }
}
