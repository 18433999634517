$primary: #0069b4 !default;
$warning: #9c7300;
$danger: #c11e1f;
$secondary: #444c57;
$success: #448d7c;
$body-bg: #fbfbfb;

$form-feedback-valid-color: $primary;
$form-feedback-invalid-color: $danger;

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

$custom-form-validation-states: (
  "warning": (
    "color": $warning,
    "icon": none,
  ),
);

$form-validation-states: map-merge(
  $form-validation-states,
  $custom-form-validation-states
);

@import "../node_modules/bootstrap/scss/mixins";

// Create your own map
$custom-colors: (
  "success": #448d7c,
  "success-hover-light": #d1e7dd,
  "primary": #0069b4,
  "primary-hover-light": #d3e6f3,
  "primary-light": #3387c3,
  "primary-light-light": #e6f0f6,
  "warning": #9c7300,
  "warning-hover-light": #f2d7bd,
  "danger": #c11e1f,
  "danger-hover-light": #f8d6da,
  "secondary": #444c57,
  "body-background": #fbfbfb,
  "gray-data-grid": #dddddd,
  "imes-yellow": #fabb00,
  "imes-yellow-light": #f8c43c,
  "primary-hover": #156fc13b,
  "danger-hover": #c95d6649,
  "light-hover": #f8f9fa3b,
  "lightblue": #3387c3,
  "lightblue-lighter": #3789cb,
  "lightblue-lighterer": #4fa8f0,
  "lightblue-hover": #64a3d6,
  "darkblue": #0069b4,
);

$form-valid-border-color: "var(--bs-primary)";
$form-valid-color: "var(--bs-primary)";
$theme-colors: map-merge($theme-colors, $custom-colors);

$font-size-base: 0.9rem !default; // Assumes the browser default, typically `16px`
